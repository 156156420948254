import subscriptionHelper from './subscriptions'
import {
    clientsGenders
} from '../ui/client'
import moment from 'moment'
import { cloneDeep } from 'lodash'

import dateHelpers from './date'


export default {
    clientsGender(x) {
        return clientsGenders.filter(elem => elem.value == x)
    },

    clientFullname(client) {
        if (!client) {
            return ''
        }

        if (client.OrgInfo && client.OrgInfo.Name) {
            if (client.Firstname || client.Lastname) {
                return client.OrgInfo.Name + ' (' + client.Firstname + ' ' + client.Lastname + ')'
            }

            return client.OrgInfo.Name
        }

        return client.Firstname + ' ' + client.Lastname
    },

    getCards(client) {
        const cards = []
        if (!client || !client.Cards) {
            return cards;
        }

        client.Cards.forEach(el => {
            if (el.CardNumber) {
                for (let i = 0; i < el.CardNumber.length; i++) {
                    cards.push({
                        CardNumber: el.CardNumber[i],
                        AttributionDate: el.AttributionDate[i],
                        CardType: el.CardType,
                        Subscriptions: el.Subscriptions
                    })
                }
            }
        })
        return cards
    },

    clientHasCardType(client, cardType) {
        return client && client.Cards && client.Cards.some(c => c.CardType == cardType && c.CardNumber && c.CardNumber.length > 0)
    },

    clientHasCardTypeCompatibleForProduct(client, product) {
        return product.Subscription &&
            product.Subscription.CardType &&
            client &&
            client.Cards &&
            client.Cards.some(c => product.Subscription.CardType.includes(c.CardType) && c.CardNumber && c.CardNumber.length > 0)
    },


    getSubscriptions(client, products) {
        const out = []
        if (!client || !client.Cards) {
            return out;
        }

        products = products === undefined ? [] : products
        var product
        if (client.Cards) {
            client.Cards.forEach(el => {
                if (el.Subscriptions) {
                    el.Subscriptions.forEach(s => {
                        product = Array.isArray(products) ? products.find(p => p.ID === s.ProductID) : products[s.ProductID]
                        out.push(Object.assign(s, { Product: product }))
                    })
                }
            })
        }
        return out
    },

    getClientSubscription(cli, subscriptionID) {
        if (!cli || !cli.Cards) {
            return null
        }

        for (const card of cli.Cards) {
            if (card.Subscriptions) {
                for (const sub of card.Subscriptions) {
                    if (sub.ID === subscriptionID) {
                        return sub
                    }
                }
            }
        }

        return null
    },

    clientHasValidSubscriptionWithProductID(client, productID, productsMap) {
        if (!client.Cards) {
            return
        }

        return client.Cards.some(c => c.Subscriptions && c.Subscriptions.some(s => s.ProductID === productID && subscriptionHelper.isSubscriptionValid(s, productsMap)))
    },

    clientHasRequiredLevelForSlot(client, slot) {
        if (!slot.Levels || slot.Levels.length === 0) {
            return true
        }

        return client.ActivitiesLevels && client.select.ActivitiesLevels.some(l => l.ActivityID === slot.ActivityID.ID && slot.Levels.includes(l.LevelID))
    },

    isClientLevelGoodForSlot(client, slot) {
        if (!slot.Levels || slot.Levels.length === 0) {
            return true
        }

        for (let level of slot.ActivityLevels) {
            if (level.Required && !client.ActivityLevels.some(l => l.ActivityID === slot.ActivityID && l.LevelID === level.ID)) {
                // Level is required and client doesn't have it
                return false
            }
        }

        return true
    },

    currentClientPricingID(client, currentPeriodID) {
        let pricingID
        // get currently active client pricing
        if (client.Pricings && client.Pricings.length > 0) {
            const currentPricingIndex = this.currentClientPricingIndex(client)
            if (currentPricingIndex >= 0) {
                return client.Pricings[currentPricingIndex].PricingID
            }
        } else if (currentPeriodID && client.Pricing && client.Pricing[currentPeriodID]) {
            return client.Pricing[currentPeriodID]
        }

        return pricingID || 'DEFAULT'
    },

    currentClientPricingIndex(client) {
        // Pricings need to be cloned for the sort to not re-trigger reactivity on itself
        const pricings = cloneDeep(client.Pricings)
        pricings.sort((a, b) => moment(a.From).isAfter(b.From) ? -1 : 1)
        return pricings.findIndex(p => (dateHelpers.isToday(p.From) || dateHelpers.isPast(p.From)) && (!p.To || dateHelpers.isFuture(p.To) || dateHelpers.isToday(p.To)))
    },

    updateClientPricing(client, newPricing, currentPeriodID) {
        if (!newPricing) {
            return client;
        }

        if (!client.Pricings) {
            client.Pricings = []
        }

        let currentClientPricingIndex = this.currentClientPricingIndex(client)
        // end the current client pricing if not starting today
        if (currentClientPricingIndex >= 0) {
            if (dateHelpers.isToday(client.Pricings[currentClientPricingIndex].From)) {
                client.Pricings.splice(currentClientPricingIndex, 1)
            } else {
                client.Pricings[currentClientPricingIndex].To = dateHelpers.formatDateAPI()
            }
        }

        // add the new pricing
        if (newPricing.ID !== "DEFAULT") {
            client.Pricings.push({
                From: moment().startOf('day').format(),
                To: newPricing.Validity ? dateHelpers.formatDateAPI(dateHelpers.addToDateTime(undefined, newPricing.Validity, 'months')) : null,
                PricingID: newPricing.ID,
            })
        }

        // for compatibility, set the pricingID to the current period
        if (currentPeriodID) {
            client.Pricing[currentPeriodID] = newPricing.ID
        }

        return client
    },
}
